<template>
  <div class="right-person" ref="pageContent">
    <div v-if="rightsVisable" class="bg">
      <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="(rightsList, itemIndex) in rightsGroupList"
          :key="itemIndex"
          :name="rightsList.productMark"
        >
          <template #title>
            <img src="@/assets/icon_card.svg" alt="" />
            {{ rightsList.productMark }}
          </template>
          <div
            v-for="(item, itemIndex) in rightsList.itemList"
            :key="itemIndex"
          >
            <div class="header">
              <div class="banner" @click="goPage(item)">
                <img :src="item.productCardImgUrl" />
                <img class="download" src="@/assets/download.png" />
                <span class="banner-card">{{
                  item.privilegeState | onFilterStatus
                }}</span>
                <p :class="[{ 'banner-bottom': true }]">
                  <span>权益人：{{ item.personName }}</span>
                  <span>最长有效期至：{{ item.privilegeExpire }}</span>
                </p>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <!-- 无权益 -->
    <div v-else>
      <div class="right-empty" v-if="!loading">
        <van-empty image-size="200px">
          <div slot="description" class="empty-bottom-text text-center">
            <p>您暂无此权益</p>
            <p>请激活权益后再次尝试</p>
            <!--<p>请激活权益后再次尝试</p>-->
          </div>
        </van-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { onFetchRightsByUserGroup, onMobileLogin } from "@/api";
export default {
  name: "rightPersons",
  data() {
    return {
      loading: true, // loading页面
      activeIndex: "",
      headerList: [
        {
          id: "",
          label: "全部",
        },
        {
          id: 1,
          label: "已生效",
        },
        {
          id: 5,
          label: "未生效",
        },
        {
          id: 2,
          label: "已失效",
        },
      ],
      productMark: "",
      show: false,
      originUrl: "http://health-new.medtreehealth.com",
      rightsVisable: false,
      activeNames: [],
      rightsGroupList: [],
      publicType: null,
      tcrt: null,
      token: '',
      toastShow: false, // 是否展示药品种类弹窗
      personItem: null, // 受益人信息
      personShow: false, // 添加受益人
      personInfo: "",
      personName: "",
      credentialsNumber: "",
      relationType: "2",
      personId: "",
      drugList: [], // 药品列表
      scrollbarStyleElement: undefined,
    };
  },
  created() {
    this.publicType = localStorage.getItem("publicType");
    this.tcrt = localStorage.getItem("tcrt");
    this.token = localStorage.getItem("token");

    this.activeIndex = localStorage.getItem("productMark") | "";

    //let userPhone = localStorage.getItem('userMobile');
    //this.initLogin(userPhone);

    this.init();
  },

  filters: {
    // 状态
    onFilterStatus(val) {
      let msg = "";
      switch (val) {
        case 1:
          msg = "已生效";
          break;
        case 2:
          msg = "已失效";
          break;
        case 3:
          msg = "已使用";
          break;
        case 4:
          msg = "已激活";
          break;
        case 5:
          msg = "未生效";
          break;
      }
      return msg;
    },
  },
  mounted() {
    const pageContent = this.$refs.pageContent;

    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const height = entry.contentRect.height;

        window.parent.postMessage(
          { loading: this.loading, height },
          this.originUrl
        );
      }
    });

    // 观察目标元素
    this.resizeObserver.observe(pageContent);
    this.addScrollbarStyle();
  },
  beforeDestroy() {
    // 销毁观察器
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    this.removeScrollbarStyle();
  },
  methods: {
    getHeight() {
      const height = this.$refs.contentRef.offsetHeight;
      console.log(`高度为：${height}px`);
    },
    showPopup() {
      this.toastShow = true;
    },
    // 切换菜单
    onHandleChangeMenu(item) {
      if (item.id === this.activeIndex) return;
      this.activeIndex = item.id;
      this.init();
    },
    goPage(item) {
      let jumpUrl = "";
      if (item.isHds) {
        let forPrivilegeList = item.privilegeList;

        for (const v of forPrivilegeList) {
          if (v.isUrlPrivilege) {
            jumpUrl = v.privilegeUrl;
            break;
          }
        }
      } else {
        jumpUrl =
          "http://www-test.healthmore.net/rightsPerson?personPrivilegeId=" +
          item.personPrivilegeId + "&token=" + this.token;
      }
      console.log("跳转页面");
      console.log(jumpUrl);

      window.parent.postMessage({ url: jumpUrl }, this.originUrl);
    },
    onShow(item, index) {
      this.personInfo = item;
      this.show = true;
    },
    async initLogin(userPhone) {
      const res = await onMobileLogin(userPhone);
      if (res.code == 200) {
        localStorage.setItem("token", res.data.token);
      }
    },
    // 获取权益
    async init() {
      this.loading = true;
      try {
        const res = await onFetchRightsByUserGroup({
          publicType: this.publicType,
          privilegeState: this.activeIndex,
          tcrt: this.tcrt,
        });
        if (res.code == 200) {
          this.rightsGroupList = res.data;
          this.rightsVisable = true;
          this.rightsGroupList.forEach((v, i) => {
            this.rightsGroupList[i]["currentIndex"] = 0;
          });
          this.activeNames = [this.rightsGroupList[0]?.productMark];
        } else if (res.code == 2014) {
          // 无权益
          this.rightsVisable = false;
        }
      } catch (err) {
        console.log("err", err);
      }
      this.loading = false;
    },
    addScrollbarStyle() {
      // 创建 <style> 标签
      this.scrollbarStyleElement = document.createElement("style");
      this.scrollbarStyleElement.type = "text/css";
      this.scrollbarStyleElement.id = "scrollbar-style"; // 给 style 一个 id 便于管理
      this.scrollbarStyleElement.innerHTML = `
        html::-webkit-scrollbar, body::-webkit-scrollbar {
          display: none;
        }
      `;
      // 将 <style> 标签插入到 <head>
      document.head.appendChild(this.scrollbarStyleElement);
      console.log("滚动条样式已添加");
    },
    removeScrollbarStyle() {
      // 检查并移除样式
      const styleElement = document.getElementById("scrollbar-style");
      if (styleElement) {
        document.head.removeChild(styleElement);
        console.log("滚动条样式已移除");
      }
    },
  },
};
</script>
<style scoped lang="less">
html,
body {
  &::-webkit-scrollbar {
    display: none;
  }
}
::v-deep .van-radio-group--horizontal {
  display: flex;

  .van-radio--horizontal {
    flex: 1;
    font-size: 12px;
  }
}

::v-deep .van-cell__title {
  display: flex;
  align-items: center;
  color: #323233;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  box-orient: vertical;
  font-size: 14px;

  img {
    display: block;
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
}

::v-deep .van-cell__right-icon {
  color: #000;
}

.table-toast {
  position: relative;
  width: 310px;

  &_max {
    max-height: 300px;
    overflow-y: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: none;
    position: relative;

    &.absolute {
      tbody {
        display: none;
      }
    }

    td,
    th {
      padding: 5px;
      font-size: 12px;
      font-weight: normal;
    }

    th {
      padding: 10px 5px;
    }

    thead {
      background: #94d3f4;
      font-size: 16px;
      font-weight: 500;
    }

    tbody {
      tr {
        &:nth-child(even) {
          background: #f2f2f2;
        }
      }
    }
  }
}

.add-person {
  padding: 20px;
  width: 300px;
}

.van-cell::after {
  border-bottom: 0;
}

.btn-options {
  button {
    margin: 0 10px;
  }
}

::v-deep .info-mask {
  background-color: rgba(0, 0, 0, 0.3) !important;

  &_content {
    width: 280px;
    padding: 10px 20px;

    h4 {
      margin: 0;
      padding: 10px;
      font-size: 18px;
    }

    p {
      margin-bottom: 10px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.right-person {
  color: #333;
  background: #f5f5f5;

  &_header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px 10px 0;
    background-color: #fff;
    z-index: 100;

    &__list {
      background: #f5f5f5;
      line-height: 30px;
      border-radius: 30px;
      padding: 5px;
      box-sizing: border-box;
      display: flex;

      span {
        flex: 1;
        font-size: 18px;
        color: #666;
        text-align: center;

        &.active {
          color: #222;
          background-color: #fff;
          border-radius: 30px;
        }
      }
    }
  }

  .bg {
    background-color: #fff;
  }

  .itemHeader {
    height: 200px;
  }

  .header {
    font-size: 12px;

    .banner {
      width: 300px;
      margin: 0 auto;
      position: relative;
      color: #fff;

      img {
        width: 100%;
      }

      .download {
        width: 54px;
        position: absolute;
        left: 4px;
        top: 24px;
        z-index: 9;
      }

      &-card {
        position: absolute;
        left: 12px;
        top: 25px;
        z-index: 19;
      }

      p {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 28px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;

        &.banner-bottom {
          width: 95%;
          margin-left: 2.5%;
          bottom: 18px;
          padding: 7px 15px;
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    & > p {
      color: #005dcc;
      font-weight: 500;
    }

    &.family {
      h2 {
        display: flex;
        align-items: center;

        i {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 4px;
          height: 20px;
          background: #1989fa;
          border-radius: 2px;
        }

        span {
          display: inline-block;
          padding: 5px 10px;
          font-size: 12px;
          color: #fff;
          background: #1989fa;
          border-radius: 30px;
          margin-left: 20px;
        }
      }

      .header-span {
        span {
          display: inline-block;
          padding: 5px 10px;
          background: #ccc;
          border-radius: 30px;
          margin: 10px 10px 0 0;

          &.active {
            color: #fff;
            background: #1989fa;
          }
        }
      }

      .header-item {
        padding: 10px 0;

        & > div {
          display: flex;
          font-size: 14px;
          line-height: 24px;

          p {
            flex: 1;
          }
        }
      }
    }
  }

  .notice {
    background: rgba(252, 137, 64, 0.03);
    border-radius: 5px;
    padding: 5px;
    color: #fc8940;
    font-size: 12px;
    margin: 10px auto 0;
    display: flex;
    align-items: center;

    .remind {
      background: #fc8940;
      padding: 2px 5px;
      color: #fff;
      font-size: 10px;
      margin-right: 5px;
      border-radius: 2px;
      text-align: center;
    }
  }

  h2 {
    margin: 10px 0;
    font-size: 18px;
    position: relative;
    padding: 0 10px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 10%;
      width: 4px;
      height: 80%;
      background: #1989fa;
    }
  }

  &_item {
    margin-bottom: 20px;

    &__title {
      background: #fff;
      border-radius: 10px 10px 0 0;
      padding: 10px;
      font-size: 14px;
      margin-bottom: 10px;

      h4 {
        font-size: 16px;
      }

      .person-info {
        margin-top: 10px;

        img {
          width: 17px;
          height: 17px;
        }
      }
    }

    &__content {
      ul {
        li {
          position: relative;
          padding: 25px 10px 10px;
          font-size: 12px;
          color: grey;
          margin-bottom: 10px;
          border-radius: 10px;
          background: #fff;

          &.unclick {
            filter: grayscale(1);
            opacity: 0.6;
          }

          .item-tag {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 10px 0 10px 0;
            overflow: hidden;
            font-size: 12px;
            padding: 3px 8px;
            color: #fff;
            background-color: #1989fa;
          }

          img {
            margin-top: 10px;
            width: 64px;
            height: 64px;
          }

          .title {
            color: #333;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
            flex: 1;
          }

          .btn-mini {
            padding-left: 10px;
            padding-right: 10px;
          }

          .desc-info {
            dl {
              dt,
              dd {
                margin-bottom: 5px;
              }

              dt {
                font-size: 14px;
                color: #fc8940;

                img {
                  margin-top: 0;
                  margin-right: 5px;
                  width: 5px;
                  height: 5px;
                }
              }

              dd {
                padding: 3px 5px;
                color: #005dcc;
                background: #f3f8ff;

                span {
                  &::after {
                    content: "、";
                  }

                  &:last-child {
                    &::after {
                      content: "";
                    }
                  }
                }
              }
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .right-empty {
    background-color: #fff;
    padding: 40px;

    .empty-bottom-text {
      width: 200px;
      text-align: center;
      font-size: 14px;

      .active {
        color: #1989fa;
      }
    }
  }
}
</style>
