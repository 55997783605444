<template>
    <div class="rights-page">
        <p :class="publicType == '3' ? 'rights-page_title_yishu' : 'rights-page_title'">
            <van-icon name="checked" />
        </p>
        <h2>恭喜您，权益激活成功</h2>
        <p>
            <van-button v-if="publicType == 3" style="background-color: #12cd9f;border: 0.02667rem solid #12cd9f;" block round type="info" size="normal" @click="goPage('rightsPersonList')">预约权益</van-button>
            <van-button v-if="publicType != 3"  block round type="info" size="normal" @click="goPage('rightsPersonList')">预约权益</van-button>
        </p>
        <p class="margin-top-sm">
            <van-button v-if="publicType == 3" class="yishuButton" style="border: 0.02667rem solid #12cd9f;" block round plain type="info" size="normal" @click="goPersonal">返回个人中心</van-button>
            <van-button v-if="publicType != 3" block round plain type="info" size="normal" @click="goPersonal">返回个人中心</van-button>
        </p>
    </div>
</template>
<script>
export default {
    name: 'rights',
    data() {
        return {
            publicType: '',
        }
    },
    created() {
        this.publicType = localStorage.getItem('publicType');
    },
    methods: {
        goPage(page) {
            window.location.href = "https://test-h5.mediext.com/health/?filterTypeCode=426#/health-center";
        },
        goPersonal() {
            window.location.href = "https://test-h5.mediext.com/health/?filterTypeCode=426#/health-center";
        }
    }
}
</script>
<style scoped lang="less">
.rights-page {
    text-align: center;
    padding: 0 40px;
    color: #333;
    .rights-page_title {
        color: #4e8fe0;
        font-size: 50px;
        margin: 70px 0 40px;
    }
    .rights-page_title_yishu {
        color: #12cd9f;
        font-size: 50px;
        margin: 70px 0 40px;
    }
    h2 {
        font-size: 20px;
        margin-bottom: 40px;
    }
}
::v-deep .yishuButton.van-button--plain.van-button--info {
    color: #12cd9f;
}
</style>